import React from "react";

class ExecutiveImageLink extends React.Component {
  render() {
    const props = this.props;
    let imgLink = "";
    let cutUrl = "";
    let url = "/";
    
    if (props.execLink.children[1].children && props.execLink.children[1].children[0].attribs) {
      url = props.execLink.children[1].children[0].attribs.href;
    }

    // if (props.execLink.children[1].children && props.execLink.children[1].children[0].attribs) {
    //   let url = props.execLink.children[1].children[0].attribs.href;
    //   if (url.substring(0, 26) === "https://polb.wpengine.com/") {
    //     cutUrl = url.slice(26);
    //   } else {
    //     cutUrl = url.slice(25);
    //   }
    // }

    // imgLink = cutUrl;

    return (
      <React.Fragment>
        {
          props.execLink.children[1].children && props.execLink.children[1].children[0].attribs ?
            <a href={url}><img className={props.execImg.attribs.class} alt="Profile picture link" src={props.execImg.attribs.src} /></a>
            :
            <img className={props.execImg.attribs.class} alt="Profile picture link" src={props.execImg.attribs.src} />
        }
      </React.Fragment>
    )
  }
}


export default ExecutiveImageLink;

