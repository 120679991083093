import React from "react";
import orderBy from 'lodash.orderby';
import ReactPaginate from 'react-paginate';

import btnSortDown from "src/images/btn-sort-down.png";
import btnSortUp from "src/images/btn-sort-up.png";

/**
 *  Table of downloadable files at bottom of most subpages
 */
class TableFiles extends React.Component {
  constructor(props) {
    super(props);

    // this.handleClickRow = this.handleClickRow.bind(this);   [see note below about why commented out]
    this.handleSortFiles = this.handleSortFiles.bind(this);
  }

  // If want to reinstate sorting the files by title in ascending order by default, 
  // replace this.props.tableData below with this:
  //    orderBy(this.props.tableData, ['title'], ['asc'])
  state = {
    sortOrderDescending: false,
    tableData: this.props.tableData.data,
    data: [],
    offset: 0,
    pageCount: 0,
    pageSelected: 0 
  }

  // Set the number of total pages and display a page
  componentDidMount() {
    this.setState({ pageCount: Math.ceil(this.state.tableData.length / this.props.perPage) }, () => {
      this.changeDataToShow();
    });
  }

  /**
   * Load file from table in new tab if user clicks anywhere in the row that isn't the link to the file.
   * (If user clicks on link to the file, target="_blank" will open it in a new tab.)
   * 
   * Note that if the browser does not support displaying that file type (ex: a Word doc), the browser's 
   * native file handling occurs. That handling varies across browsers. It may download the file 
   * automatically, or prompt the user to save the file or view it in a program of their choice.
   */
  // Commenting out to see if PDFs will display in tab when site deployed in https environment (i.e., dev)
  // handleClickRow(e) {
  //   if (e.target.nodeName !== 'A') {
  //     const rowChildren = e.currentTarget.children;
  //     window.open(rowChildren[rowChildren.length - 1].querySelector('a').href, '_blank');
  //   }
  // }

  // Sort table rows by Name column, toggling between ascending and descending order
  handleSortFiles() {
    const descendingOrder = !this.state.sortOrderDescending;
    const order = descendingOrder ? 'desc' : 'asc';
    const data = orderBy(this.state.tableData, ['title'], [order]);

    this.setState({ sortOrderDescending: descendingOrder, tableData: data }, () => {
      this.changeDataToShow();
    });
  }

  /**
   * For react.paginate
   */
  // Change which data is currently shown in table
  changeDataToShow() {
    const allData = this.state.tableData; // all of the files table data
    const perPage = parseInt(this.props.perPage, 10);

    let { offset, pageSelected } = this.state;
    offset = parseInt(offset, 10);
    pageSelected = parseInt(pageSelected, 10);

    let data = allData.slice(offset, pageSelected * perPage + perPage);

    this.setState({
      data: data
    });
  }

  // A pagination number was clicked, so change data shown
  handlePageClick = data => {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.props.perPage);

    this.setState({ offset: offset, pageSelected: selected }, () => {
      this.changeDataToShow();
    });
  };

  render() {
    const { data, offset, pageSelected } = this.state;

    return (
      <div className="table-overflow">
        <table className="files">
          <caption className="visuallyhidden">Files you may download</caption>
          <thead>
            <tr>
              <th scope="col">Name  
                { 
                  this.state.tableData.length > 2
                  && <button 
                      type="button" 
                      className="btn-sort" 
                      onClick={this.handleSortFiles}>
                      <img src={this.state.sortOrderDescending ? btnSortUp : btnSortDown} alt="sort files by name" />
                      </button>
                }
              </th>
              <th scope="col">Size</th>
              <th scope="col">File</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{row.title.replace("&amp;", "&")}</td>
                  <td>{row.size}</td>
                  <td><a href={`${row.linkdownload.replace("https:\/\/thehelm.polb.com\/download\/","https://polb.com\/download\/")}`} rel="noopener noreferrer" target="_blank">{row.ext.substring(0,3)}</a></td>
                </tr>
              )
            })}
          </tbody>
        </table>

        { this.state.tableData.length > this.props.perPage &&
          <div className="pagination-wrapper">
            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              pageClassName={'pagination__item'}
              nextClassName={'pagination__item pagination__item--next'}
              previousClassName={'pagination__item pagination__item--prev'}
              activeClassName={'pagination__item--active'}
            />

            <p className="pagination-range">Viewing {offset + 1}–{data.length + (pageSelected * this.props.perPage)} of {this.state.tableData.length} results</p>
          </div>
        }
      </div>
    );
  }
}

// Use this if reapply behavior for clicking on table row to open document.
//  <tr key={index} onClick={this.handleClickRow}>

export default TableFiles;