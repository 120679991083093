import React from "react";
import photo from "src/images/port-info/contact-us.jpg";
import ReactIframeResizer from "react-iframe-resizer-super";

/**
 *  Contact Us form
 */
class Contact extends React.Component {
  render() {

    const iframeResizerOptions = {
      log: true,
      checkOrigin: false,
      scrolling: false,
      heightCalculationMethod: 'documentElementOffset'
    };

    return (
      <section className="contact-us">
        <div className="left">
          <img src={photo} alt="Port of Long Beach" className="contact-us__img" />
        </div>
        <div className="right">
          <div className="content">
            <h2 className="contact-us__heading heading heading--lg line">Have a Comment or Question?</h2>
            <p className="contact-us__intro intro">Please use the form to contact us and we will get back to you shortly or use the menu below to find a phone number or email address.</p>
          </div>

          <ReactIframeResizer
            id={'contact-us-iframe'}
            key={'contact-us-iframe'}
            src={`${process.env.GATSBY_API_URL}/gfembed/?f=1`}
            iframeResizerOptions={iframeResizerOptions}
            iframeResizerUrl={false}
            className="stellar-gravity-form" />
        </div>
      </section>
    );
  }
}

export default Contact;
