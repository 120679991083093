import React from "react";

import team from "src/images/jobs/join-team-2.jpg";

/**
 *  Jobs: Join Our Team
 */
class JoinTeam extends React.Component {
  render() {
    return (
      <section className="join-team">
        <img src={team} alt="A group of Port of Long Beach workers wearing safety vests and hard hats, smiling and waving." className="join-team__img" />

        <article className="jobs__txt join-team__txt">
          <h2 className="line heading--med line--med">Harbor Patrol Is Hiring</h2>
          <p>Interested in maritime operations, environmental stewardship, critical infrastructure protection, homeland security or public service? A career at the Port of Long Beach may be for you!</p>
          <a href="/port-info/security#harbor-patrol">Find out more about becoming a member of the Harbor Patrol. </a>
        </article>
      </section>
    );
  }
}

export default JoinTeam;
