import React from "react";

import harbor from "src/images/jobs/harbor-dept-openings.jpg";
import leadership from "src/images/jobs/exec-leadership-openings.jpg";

/**
 *  Jobs: Opportunities
 */
class Opportunities extends React.Component {
  render() {
    return (
      <section className="jobs-opps-wrapper">
        <div className="jobs-opps jobs__txt gutter">
          <h2>Join Our Team</h2>
          <p>The Port of Long Beach accepts applications only for positions that are currently open for filing. Job opportunity information is updated as positions become available. Please check our site regularly!</p>

          <div className="jobs-opps__types">
            {/* ==== Start Harbor Department Openings ==== */}
            <div className="jobs-opps__type">
              <div className="card">
                <div className="jobs-opps__photo-txt card__photo-txt">
                  <h3 className="jobs-opps__heading card__title">Harbor Department Openings</h3>
                  <img src={harbor} alt="A man and woman smiling while having a discussion in a conference room." />
                </div>
              </div>
              
              <p>Openings for Harbor Department positions, both civil service and non-civil service. You will also find jobs for other city departments and college internship positions (if available) here.</p>

              <p className="jobs-opps__view"><a href="https://www.governmentjobs.com/careers/longbeach" rel="external noopener noreferrer" target="_blank">View Openings</a></p>
            </div>
            {/* end */}

            {/* ==== Start Executive Leadership Jobs ==== */}
            <div className="jobs-opps__type">
              <div className="card">
                <div className="jobs-opps__photo-txt card__photo-txt">
                  <h3 className="jobs-opps__heading card__title">Executive Leadership Jobs</h3>
                  <img src={leadership} alt="A man and woman shake hands while another man looks on." />
                </div>
              </div>
              
              <div dangerouslySetInnerHTML={{ __html: this.props.leadershipJobs }} />
            </div>
            {/* end */}

          </div>
          {/* end types */}
        </div>
      </section>
    );
  }
}

export default Opportunities;
