import React from "react";
import { Link } from "gatsby";

import card1 from "src/images/resources/chassis-card.jpg";
import card2 from "src/images/resources/demurrage-card.jpg";
import card3 from "src/images/resources/tariff-card.jpg";

/**
 *  Information Resources on resource pages
 */
class InformationResources extends React.Component {
  render() {
    return (
      <section className="info-resources-wrapper gutter">
        <div className="info-resources">
          <h2 className="heading heading--med line">Information Resources</h2>

          <ul className="card card--rect-3">
            <li>
              <a href="http://www.pop-lalb.com/" rel="noopener noreferrer" target="_blank">
                <div className="card__photo-txt">
                  <img src={card1} alt="" />
                  <p className="card__title">Chassis Information</p>
                </div>
                <p className="card__blurb">The Pool of Pools website features key information on chassis utilization.</p>
                <p className="view-more">View More</p>
              </a>
            </li>
            <li>
              <Link to="/business/port-operations-and-facilities#demurrage-calendar">
                <div className="card__photo-txt">
                  <img src={card2} alt="" />
                  <p className="card__title">Demurrage Calendar</p>
                </div>
                <p className="card__blurb">Demurrage calendar information for inbound and outbound cargo.</p>
                <p className="view-more">View More</p>
              </Link>
            </li>
            <li>
              <Link to="/business/port-operations-and-facilities#port-tariff">
                <div className="card__photo-txt">
                  <img src={card3} alt="" />
                  <p className="card__title">Port Tariff</p>
                </div>
                <p className="card__blurb">Port of Long Beach Tariff No. 4 provides current rates, rules and regulations governing the Port.</p>
                <p className="view-more">View More</p>
              </Link>
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

export default InformationResources;
