import React from "react";
import { Link } from "gatsby";

/**
 *  Jobs: Jobs at the Port
 */
class JobsAtPort extends React.Component {
  render() {

    return (
      <section className="jobs-port gutter">
        <article className="jobs__txt">
          <h2>Jobs At The Port</h2>
          <h4 className="job__title">Port of Long Beach/Harbor Department</h4>
          <p>Open positions at the Port of Long Beach, also known as the Harbor Department of the City of Long Beach, as well as jobs for other City of Long Beach departments, are listed below under <strong>Harbor Department Openings</strong>. Open college internship positions are also listed there.</p>
          <p>However, only a small number of the people whose livelihood depends on the Port are employed directly by the Harbor Department.</p>
          <h4 className="job__title">Longshore Labor</h4>
          <p>Workers within terminals are members of various labor unions, primarily the International Longshore and Warehouse Union. Through the union, these workers are employed by the terminal operators, who lease land from the Port of Long Beach. See our <strong>More Hiring Resources</strong> section below for more information.</p>
          <h4 className="job__title">Construction, Training and More</h4>
          <p>Most construction and environmental project jobs are filled by the contractors hired to complete the projects. Many of these jobs require specific skills. Our <strong>Training and Careers</strong> sections below feature information on these jobs, training programs, and career pathways to take if you’re just starting out on your career, or transitioning into a new one.</p>
          <h4 className="job__title">Weekly Job Posting Emails</h4>
          <p>To receive a weekly email on open positions at the Harbor Department, go to <a href="/subscribe/" target="_blank">polb.com/subscribe</a>, enter your email address and select the Jobs/Careers option. On sign-up you will receive the latest email and then a weekly email each Sunday at 4 p.m. Pacific with currently open Harbor Department jobs.</p>
        </article>

        <div className="jobs-port__video embed-container embed-container--16x9"><iframe width="720" height="405" src="https://www.youtube-nocookie.com/embed/Dg8QLKKN7pU" frameBorder="0" allowFullScreen title="Video: Working at the Port of Long Beach"></iframe></div>
        </section>
    );
  }
}

export default JobsAtPort;
