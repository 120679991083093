import React from "react";

class DynamicStats extends React.Component {
    render() {
        return (
            <ul className="stats">
                <li className="stat">
                    {/* <span className="stat__num line">{this.props.statOne.children[0].children[0].data}<span className="stat__sign">%</span></span> */}
                    <span className="stat__num line">{this.props.statOne.children[0].children[0].data}%</span>
                    <span className="stat__txt">{this.props.statOne.children[2].children[0].data}</span>
                </li>
                <li className="stat">
                    <span className="stat__num line">{this.props.statTwo.children[0].children[0].data}%</span>
                    <span className="stat__txt">{this.props.statTwo.children[2].children[0].data}</span>
                </li>
                <li className="stat">
                    <span className="stat__num line">{this.props.statThree.children[0].children[0].data}%</span>
                    <span className="stat__txt">{this.props.statThree.children[2].children[0].data}</span>
                </li>
            </ul>
        )
    }
}


export default DynamicStats;
