import React from "react";

/**
 * Gallery: custom view for react-images. Represents a single image or video shown in gallery.
 * This is used instead of the default react-images <View /> component, which only supports images.
 * See http://jossmac.github.io/react-images/#/components for more info on custom components.
 */
class GalleryAlbumCustomView extends React.Component {
  render() {
    // react-images automatically passes in the info for the specific media item being rendered as an object named data on props
    const { data } = this.props;
    // console.log("-----------------VIEW------------------");
    // console.log(this.props);

    return (
      <React.Fragment>
        {
          !data.video 
          ?
          <div className="react-images__view react-images__view--isModal">
            <img src={data.full} alt={data.caption} className="react-images__view-image" />
          </div>
          : 
          <div className="react-images__view--isVideo react-images__view--isModal">
            <iframe src={data.video.embed_url} width="640" height="360" title={data.caption} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen className="react-images__view-video"></iframe>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default GalleryAlbumCustomView;
