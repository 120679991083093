import React from "react";

class ExecutiveNameLink extends React.Component {
  render() {
    const props = this.props
    let link = "";
    let cutUrl = "";
    let url = props.execName.children[0].attribs.href;

    // if (url.substring(0, 26) === "https://polb.wpengine.com/") {
    //   cutUrl = url.slice(26);
    // } else {
    //   cutUrl = url.slice(25);
    // }

    // link = cutUrl;

    return (
      <strong><a href={url}>{props.execName.children[0].children[0].data} </a></strong>
    )
  }
}


export default ExecutiveNameLink;