import React from "react";
import orderBy from 'lodash.orderby';
import ReactPaginate from 'react-paginate';

import btnSortDown from "src/images/btn-sort-down.png";
import btnSortUp from "src/images/btn-sort-up.png";
import Gallery from "src/components/gallery/gallery";

/**
 *  Table of downloadable files in subpages of Documents page
 */
class TableFilesDocuments extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickRow = this.handleClickRow.bind(this);
    this.handleSortFiles = this.handleSortFiles.bind(this);
    this.combineTablesData = this.combineTablesData.bind(this);
  }

  /**
   * State
   *
   * sortOrderDescending:   when true, sort in descending order by title
   *                          (i.e., reverse alphabetical)
   * tableData:             all of the data for table, not just the subset 
   *                          shown per pagination page
   * 
   * Values used by react-paginate:
   * data:          the subset of tableData that represents the current list of files we're showing
   * offset:        which page of data we are showing
   * pageCount:     number of total pages
   * pageSelected:  the current page number we are showing (zero-based)
   *
   * To NOT sort the data in ascending alphabetical order by default, use this:
   *    tableData: this.combineTablesData(this.props.tableData),
   * That makes the data be in the order of the folders (and the files within them) in the WP page.
   */
  state = {
    sortOrderDescending: false,
    tableData: orderBy(this.combineTablesData(this.props.tableData), ['title'], ['asc']),
    data: [],
    offset: 0,
    pageCount: 0,
    pageSelected: 0 
  }

  // Set the number of total pages and display a page
  componentDidMount() {
    this.setState({ pageCount: Math.ceil(this.state.tableData.length / this.props.perPage) }, () => {
      this.changeDataToShow();
    });
  }

  // Update state when Photos & Videos galleries data received (it's fetched asynchronously), and show the results
  componentWillReceiveProps(nextProps) {
    if (nextProps.isGalleries && nextProps.tableData.length > 0) {
      this.setState({
        pageCount: Math.ceil(nextProps.tableData.length / nextProps.perPage),
        tableData: orderBy(nextProps.tableData, ['title'], ['asc']),
      }, () => {
        this.changeDataToShow();
      });
    }
  }

  // Return a single flattened array of all files table data arrays
  combineTablesData(allData) {
    const tableDataArrays = allData.map(table => table.data);

    return [].concat.apply([], tableDataArrays);
  }

  /**
   * For react.paginate
   */
  // Change which data is currently shown in table
  changeDataToShow() {
    const allData = this.state.tableData; // all of the files table data
    const perPage = parseInt(this.props.perPage, 10);

    let { offset, pageSelected } = this.state;
    offset = parseInt(offset, 10);
    pageSelected = parseInt(pageSelected, 10);

    let data = allData.slice(offset, pageSelected * perPage + perPage);

    this.setState({
      data: data
    });
  }

  // A pagination number was clicked, so change data shown
  handlePageClick = data => {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.props.perPage);

    this.setState({ offset: offset, pageSelected: selected }, () => {
      this.changeDataToShow();
    });
  };


  /**
   * Load file from table in new tab if user clicks anywhere in the row that isn't the link to the file.
   * (If user clicks on link to the file, target="_blank" will open it in a new tab.)
   * 
   * Note that if the browser does not support displaying that file type (ex: a Word doc), the browser's 
   * native file handling occurs. That handling varies across browsers. It may download the file 
   * automatically, or prompt the user to save the file or view it in a program of their choice.
   */
  handleClickRow(e) {
    if (e.target.nodeName !== 'A') {
      const rowChildren = e.currentTarget.children;
      window.open(rowChildren[rowChildren.length - 1].querySelector('a').href, '_blank');
    }
  }

  // Sort table rows by Name column, toggling between ascending and descending order
  handleSortFiles() {
    const descendingOrder = !this.state.sortOrderDescending;
    const order = descendingOrder ? 'desc' : 'asc';
    const data = orderBy(this.state.tableData, ['title'], [order]);

    this.setState({ sortOrderDescending: descendingOrder, tableData: data }, () => {
      this.changeDataToShow();
    });
  }

  render() {
    const props = this.props;

    // This is how it is done for a non-paginated table
    // let data = this.state.tableData;
    // This is for a paginated version:
    const { data, offset, pageSelected } = this.state;
    // console.log(props);

    return (
      <React.Fragment>
        <div className="files-wrapper">
          <table className="files">
            <caption className="visuallyhidden">Files you may download or view</caption>
            <thead>
              <tr>
                <th scope="col">Name  
                  { 
                    this.state.tableData.length > 2
                    && <button 
                        type="button" 
                        className="btn-sort" 
                        onClick={this.handleSortFiles}>
                        <img src={this.state.sortOrderDescending ? btnSortUp : btnSortDown} alt="sort files by name" />
                        </button>
                  }
                </th>
                <th scope="col">{!props.isGalleries ? 'Size' : 'Items'}</th>
                <th scope="col">{!props.isGalleries ? 'File' : 'Gallery'}</th>
              </tr>
            </thead>
            <tbody>
              {/* 
                For all Documents page views except Photos & Videos, the <tr> provides the size of the file and a link to the file. For galleries, it has the number of items in the gallery and a button to view the gallery.
              */}
              {data.map((row, index) => {
                
                return (
          
                  !props.isGalleries 
                  ?
                  <tr key={index} onClick={this.handleClickRow}>
                    <td>{row.title}</td>
                    <td>{row.size}</td>
                
                    <td><a href={`${row.linkdownload.replace("https:\/\/thehelm.polb.com\/download\/","https://polb.com\/download\/")}`} rel="noopener noreferrer" target="_blank">{row.ext.substring(0,3)}</a></td>
                  </tr>
                  :
                  <tr key={index}>
                    <td>{row.title}</td>
                    <td>{row.galleryInfo.length}</td>
                    <td><Gallery key={index} galleryInfo={row.galleryInfo} previewMode="button" galleryButtonText="View" isInDocumentsPage /></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        { this.state.tableData.length > this.props.perPage &&
          <div className="pagination-wrapper">
            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              pageClassName={'pagination__item'}
              nextClassName={'pagination__item pagination__item--next'}
              previousClassName={'pagination__item pagination__item--prev'}
              activeClassName={'pagination__item--active'}
            />

            <p className="pagination-range">Viewing {offset + 1}–{data.length + (pageSelected * this.props.perPage)} of {this.state.tableData.length} results</p>
          </div>
        }
      </React.Fragment>
    );
  }
}

export default TableFilesDocuments;
