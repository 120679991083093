import React from "react";


class AccordionButton extends React.Component {
    constructor(props) {
        super(props);
        this.showAndHideTable = this.showAndHideTable.bind(this);
    }

    state = {
        buttonClick: true
    }

    showAndHideTable() {
        this.setState({ buttonClick: !this.state.buttonClick });
    }


    render() {
        let hiddenClass = () => {
            let addedClass = "";

            if (this.state.buttonClick) {
                addedClass = "showTables"
            } else {
                addedClass = "";
            }

            return addedClass;
        }

        return (
            <button className={`accordion-button ${hiddenClass()}`} onClick={this.showAndHideTable}>{this.props.yearTitle}</button>
        )
    }
}

export default AccordionButton;