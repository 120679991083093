import React from "react";

/**
 *  Vessels at a Glance heading on Resources pages
 */
class VesselsHeading extends React.Component {
  render() {
    // Is in this form: <p>Updated: 6/24/2019 0900 hours</p>
    // const content = this.props.whenUpdated.content;
    // const whenUpdated = content.substring((content.indexOf('>') + 1), content.lastIndexOf('<'));

    return (
      <React.Fragment>
        <h2 className="vessels__heading heading heading--med line">Container Vessels at Berth</h2>
        <p className="intro vessels__text">Information on current vessel activity in the Port of Long Beach, provided in part by the <a href="https://mxsocal.org/" target="_blank">Marine Exchange of Southern California</a>.</p>
      </React.Fragment>
    );
  }
}

export default VesselsHeading;

//<span className="vessels__updated">{whenUpdated}</span>
