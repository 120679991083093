import React from "react";
import { Link } from "gatsby";

import card0 from "src/images/resources/port-map.jpg";
import card1 from "src/images/resources/clean-trucks-program.jpg";
import card2 from "src/images/resources/pierpass.jpg";
import card3 from "src/images/resources/port-operations-and-facilities.jpg";
import card4 from "src/images/resources/port-stats.jpg";
import card5 from "src/images/resources/foreign-trade-zone.jpg";
import card6 from "src/images/resources/temp-birth-assignment.jpg";
import card7 from "src/images/resources/incentives.jpg";
import card8 from "src/images/resources/oversize-cargo.jpg";
import card9 from "src/images/resources/wharfinger.jpg";
import card10 from "src/images/resources/card-truck-text-alerts.jpg";
import card11 from "src/images/resources/card-port-operations-alt.jpg";


/**
 *  Cards on Resource pages
 */
class ResourceCards extends React.Component {

  // Nine unique cards used across the pages in various combinations
  state = {
    cards: [
      {
        blurb: 'Orient yourself; find Port highlights and terminal info.',
        imgSrc: card0,
        title: 'Port Map',
        link: '/port-info/map'
      },
      {
        blurb: 'Truck eligibility, registration, hotline info and more.',
        imgSrc: card1,
        title: 'Clean Trucks Program',
        link: '/environment/clean-trucks#program-details'
      },
      {
        blurb: 'More information on this San Pedro Bay ports partnership.',
        imgSrc: card2,
        title: 'PierPASS',
        link: '/business/pierpass'
      },
      {
        blurb: 'Detailed facility and contact info.',
        imgSrc: card3,
        title: 'Port Facilities',
        link: '/business/port-facilities'
      },
      {
        blurb: 'Data on cargo moving through the Port.',
        imgSrc: card4,
        title: 'Port Stats',
        link: '/business/port-statistics#latest-monthly-teus'
      },
      {
        blurb: 'Reduce costs by managing customs duties.',
        imgSrc: card5,
        title: 'Foreign Trade Zone',
        link: '/business/foreign-trade-zone-ftz#ftz-overview-and-benefits'
      },
      {
        blurb: 'Lorem ispum dolor sit amet.',
        imgSrc: card6,
        title: 'Temp. Berth Assignment',
        link: '/'
      },
      {
        blurb: 'Dockage incentives programs tied to environmental goals.',
        imgSrc: card7,
        title: 'Incentives',
        link: '/business/incentives#green-flag-program'
      },
      {
        blurb: 'Maps, vertical clearance, permit info, useful links.',
        imgSrc: card8,
        title: 'Overweight / Oversize Cargo',
        link: '/business/port-operations#overweight-oversize-cargo/overweight-oversize-cargo-details'
      },
      {
        blurb: 'Port divisions and customer contact information.',
        imgSrc: card6,
        title: 'Port Directory',
        link: '/port-info/contact-us#port-directory'
      },
      {
        blurb: 'Map of area assignments and contact info.',
        imgSrc: card9,
        title: 'Wharfingers',
        link: '//thehelm.polb.com/download/471/wharfingers/11252/wharfingers-area-assignments-080119-2.pdf'
      },
      {
        blurb: 'Sign up for text notifications on traffic conditions within the Port.',
        imgSrc: card10,
        title: 'Traffic Alerts',
        link: '/resources/truckers/#sign-up-for-alerts'
      },
      {
        blurb: 'Gate hours, vessel and cargo information and more.',
        imgSrc: card11,
        title: 'Port Operations',
        link: '/business/port-operations#operations-dashboard'
      }
    ]
  }

  render() {
    return (
      <section className="cards cards--resource gutter">
        <h2 className="heading heading--med line">Quick Links</h2>
        <p className="intro">Go directly to the information you’re looking for.</p>

        {this.props.cards.length === 5 ?
          <ul className="card card--rect-5 card--environment card--tenants">
            {
              this.props.cards.map(card => {
                return (
                  <li key={card}>
                    {this.state.cards[card].title === "Wharfingers" ?
                      <a href={this.state.cards[card].link} target="_blank">
                        <div className="card__photo-txt">
                          <img src={this.state.cards[card].imgSrc} alt="" />
                          <p className="card__title">{this.state.cards[card].title}</p>
                        </div>
                        <p className="card__blurb">{this.state.cards[card].blurb}</p>
                        <p className="view-more">View More</p>
                      </a>
                      :
                      <Link to={this.state.cards[card].link}>
                        <div className="card__photo-txt">
                          <img src={this.state.cards[card].imgSrc} alt="" />
                          <p className="card__title">{this.state.cards[card].title}</p>
                        </div>
                        <p className="card__blurb">{this.state.cards[card].blurb}</p>
                        <p className="view-more">View More</p>
                      </Link>
                    }
                  </li>
                )
              })
            }
          </ul>
          :
          <ul className="card card--rect-4">
            {
              this.props.cards.map(card => {
                return (
                  <li key={card}>
                    <Link to={this.state.cards[card].link}>
                      <div className="card__photo-txt">
                        <img src={this.state.cards[card].imgSrc} alt="" />
                        <p className="card__title">{this.state.cards[card].title}</p>
                      </div>
                      <p className="card__blurb">{this.state.cards[card].blurb}</p>
                      <p className="view-more">View More</p>
                    </Link>
                  </li>
                )
              })
            }
          </ul>}
      </section>
    );
  }
}

export default ResourceCards;
