import React from "react";

import GalleryAlbumThumbnails from "src/components/gallery-album-thumbnails";
import btnToggleGrid from "src/images/btn-gallery-grid.png";

/**
 * Gallery: custom footer for react-images. 
 * This is used instead of the default react-images <Footer /> component.
 * See http://jossmac.github.io/react-images/#/components for more info on custom components.
 */
class GalleryAlbumCustomFooter extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleThumbnails = this.handleToggleThumbnails.bind(this);
    this.handleThumbnailSelected = this.handleThumbnailSelected.bind(this);
  }

  state = {
    galleryThumbnailsIsOpen: false
  };

  // Show and hide the thumbs grid (<GalleryThumbnails />)
  handleToggleThumbnails() {
    this.setState({ galleryThumbnailsIsOpen: !this.state.galleryThumbnailsIsOpen })
  }

  // Switch carousel to thumb selected
  handleThumbnailSelected(selectedIndex, modalCurrentlyOpen) {
    // close thumbs grid
    // console.log(selectedIndex);
    // console.log(this.props);
    this.setState({ 
      galleryThumbnailsIsOpen: !this.state.galleryThumbnailsIsOpen
    }, 
      // show selected thumb in carousel
      this.props.carouselProps.toggleModal(selectedIndex, true, modalCurrentlyOpen)
    );
  }

  render() {
    const { currentIndex, currentView, views } = this.props;
    const galleryThumbsOpen = this.state.galleryThumbnailsIsOpen;
    const activeView = currentIndex + 1;
    const totalViews = views.length;
    const countText = !galleryThumbsOpen ? `${activeView} of ${totalViews}` : `1–${totalViews}`;
    const captionText = !galleryThumbsOpen ? `${currentView.caption}` : 'Full Gallery';

    // console.log("-----------------FOOTER------------------");
    // console.log(this.props);

    return (
      <React.Fragment>
        <footer className="react-images__footer react-images__footer--isModal">
          <button 
            type="button" 
            className={`gallery__btn-toggle-grid ${galleryThumbsOpen && 'gallery__btn-toggle-grid--isActive'}`} 
            onClick={this.handleToggleThumbnails} 
            title="Toggle gallery thumbnails view"><img src={btnToggleGrid} alt="Toggle gallery thumbnails view" /></button>
          <span className="react-images__footer__count react-images__footer__count--isModal">{countText}</span> 
          <span className="react-images__footer__caption react-images__footer__caption--isModal">{captionText.replace(/Ó/, '').replace(/Ò/, '').replace(/Õ/, '').replace(/&amp;/, '&').replace(/Ô/, '').replace(/Õ/, "'").replace(/Ð/, '')}</span>
        </footer>

        <GalleryAlbumThumbnails 
          thumbs={views} 
          showThumbs={galleryThumbsOpen} 
          handleThumbnailSelected={this.handleThumbnailSelected} 
        />
      </React.Fragment>
    );
  }
}

export default GalleryAlbumCustomFooter;
