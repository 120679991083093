import React from "react";

/**
 *  POLB Customer Portal Information on Tenants resource page
 */
class CustomerPortal extends React.Component {
  render() {
    return (
      <section className="portal gutter">
        <h2 className="heading heading--med line">POLB Customer Portal Information</h2>
        <p>The Port’s online Customer Portal, created in partnership with Ignify, Inc. of Long Beach, is available to all Port customers with a valid e-mail address.</p>
        <p>Customers can securely log in and view their vessel calls, invoices, payment history, annual minimums, breakpoints, lease terms and lease documents. Invoices can be reprinted or downloaded into a Microsoft Excel spreadsheet; customers can also pay invoices online using eChecks at no additional cost.</p>
        <p>To request access, please send an email to <strong>billing@polb.com</strong> with the subject line “Customer Portal Access Request.” Be sure to include your company name, your name and your telephone number.</p>

        {/*<ul className="portal__btns">
          <li><a href="http://polb.com/" rel="noopener noreferrer" target="_blank" className="main--link"><span className="main--link__text">Register</span></a></li>
          <li><a href="http://polb.com/" rel="noopener noreferrer" target="_blank" className="main--link"><span className="main--link__text">Log In</span></a></li>
          </ul>*/}
      </section>
    );
  }
}

export default CustomerPortal;
