import React from "react";
import ReactIframeResizer from 'react-iframe-resizer-super';

/**
 *  Render Visualizer table in iframe
 */
class VisualizerIframe extends React.Component {
  render() {
    const iframeResizerOptions = {
      checkOrigin: false,
      minHeight: 200,
      scrolling: true,
      heightCalculationMethod: 'documentElementOffset'
    }

    return (
      <ReactIframeResizer 
        src={this.props.iframeSrc} 
        iframeResizerOptions={iframeResizerOptions} 
        iframeResizerUrl={false} 
        className="visualizer"></ReactIframeResizer>
    );
  }
}

export default VisualizerIframe;
