import React from "react";
import PropTypes from "prop-types";

/**
 *  Announcement at bottom of sidebar on subpages
 */
class Announcement extends React.Component {
  render() {
    const props = this.props;

    return (
      <div className="announcement">
        { props.title && <h3 className="announcement__heading line">{props.title}</h3> }
        { props.content && <div className="announcement__inner" dangerouslySetInnerHTML={{__html: props.content}} /> }
      </div>
    );
  }
}

Announcement.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string
}

Announcement.defaultProps = {
  content: '',
  title: ''
}

export default Announcement;

// Hard-coded content for testing. TODO: remove after creating various announcement content types.
// <div className="announcement__inner">
// <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Porro et quisquam, odit ab inventore.</p>
// <ul>
//   <li><a href="#">Ports of Long Beach and Los Angeles</a></li>
//   <li><a href="#">Clean Trucks Program</a></li>
//   <li><a href="#">Drayage Registry</a></li>
// </ul>
// <a href="/" className="announcement__btn">Board Meetings</a>
// </div>