import React from "react";


class TitleReplace extends React.Component {
  render() {
    const props = this.props;
    const uneditedTitle = props.nodeInfo.children[0].data;
    let firstHalf = uneditedTitle.slice(0, uneditedTitle.indexOf("<br>"));
    let secondHalf = uneditedTitle.slice(uneditedTitle.indexOf("<br>") + 4);

    return (
      <React.Fragment>
        {
          props.nodeInfo.children[0].data.indexOf("<br>") > -1 ?

          <h4 className="executive__title">{firstHalf}<br />{secondHalf}</h4>
          :
          <h4 className="executive__title">{uneditedTitle}</h4>
        }
      </React.Fragment>
    )
  }
}

export default TitleReplace;