import React from "react";

import JoinTeam from "src/components/jobs/join-team";
import JobsAtPort from "src/components/jobs/jobs-at-port";
import Opportunities from "src/components/jobs/opportunities";

/**
 *  Jobs page
 */
class Jobs extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="homepage-break-div"></div>
        <JobsAtPort />
        <Opportunities leadershipJobs={this.props.content} />
        {/* <JoinTeam /> */}
      </React.Fragment>
    );
  }
}

export default Jobs;
