import React from "react";
import { StaticQuery, graphql } from 'gatsby';

import ReactIframeResizer from "react-iframe-resizer-super";
import VesselsHeading from "src/components/resource/vessels-heading";
// import VisualizerIframe from "src/components/visualizer-iframe";

/**
 *  Vessels at Glance on resource pages
 */
class Vessels extends React.Component {
  render() {
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    let tableUrl = `${process.env.GATSBY_API_URL}/stellar_custom_table/table109`;
    const iframeResizerOptions = isIE ?
      {
        checkOrigin: false,
        minHeight: 200,
        scrolling: false,
        heightCalculationMethod: 'lowestElement'
      }
      :
      {
        checkOrigin: false,
        minHeight: 200,
        scrolling: false,
        heightCalculationMethod: 'documentElementOffset'
      };

    // console.log(iframeResizerOptions);
    // console.log(document.documentElement.offsetHeight);

    // for iframe sizer to work, we need a unique ID every time we generate this iframe
    // otherwise, on tab switches, the iframe wont be resized
    Vessels.uniqueID++;


    return (
      <section className="vessels gutter">

        {/* Heading with info about when the data was last updated. */}
        <StaticQuery
          query={graphql`
            query {
              allWordpressPage(
                filter: {
                  tags: {
                    elemMatch: {
                      name: {
                        eq: "dev_only_id_resource_cargo_owners"
                      }
                    }
                  }
                }
              ) {
                edges {
                  node {
                    content
                  }
                }
              }
            }
          `}
          render={data => (
            <VesselsHeading
              whenUpdated={data.allWordpressPage.edges[0].node}
            />
          )}
        />


        <ReactIframeResizer
          key={'custom-table-' + Vessels.uniqueID}
          id={'custom-table-' + Vessels.uniqueID}
          src={tableUrl}
          iframeResizerOptions={iframeResizerOptions}
          iframeResizerUrl={false}
          className="stellar-custom-table" />

        {/*<VisualizerIframe key="5181" iframeSrc="https://thehelm.polb.com/visualizer/?chart=5181&type=2" />*/}
      </section>
    );
  }
}

export default Vessels;