import React from "react";

// Used by all resource pages
import InformationResources from "src/components/resource/information-resources";
import ResourceCards from "src/components/resource/resource-cards";
import Vessels from "src/components/resource/vessels";

// Used only on Tenants
import CustomerPortal from "src/components/resource/customer-portal";

/**
 *  Resource page in the /resources section
 */
class Resource extends React.Component {
  render() {
    const props = this.props;

    return (
      <React.Fragment>
        { /* Quick Links cards for Cargo Owners. */ }
        { props.tagNames.includes('dev_only_id_resource_cargo_owners') 
          && <ResourceCards cards={[12,3,4,5,2]} />
        }

        { /* Quick Links cards for Ocean Carriers. */ }
        { props.tagNames.includes('dev_only_id_resource_ocean_carriers') 
          && <ResourceCards cards={[12,3,4,9,10]} />
        }

        { /* Quick Links cards for Tenants. */ }
        { props.tagNames.includes('dev_only_id_resource_tenants') 
          && <ResourceCards cards={[0,3,4,9,10]} />
        }
        
        { /* Quick Links cards for Truckers. */ }
        { props.tagNames.includes('dev_only_id_resource_truckers') 
          && <ResourceCards cards={[12,3,1,8,11]} />
        }

        <Vessels />

        { /* Used only on Tenants. */ }
        { props.tagNames.includes('dev_only_id_resource_tenants') 
          && <CustomerPortal />
        }

        <InformationResources />
      </React.Fragment>
    );
  }
}

export default Resource;
